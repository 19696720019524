<template>
    <b-container>
        <b-overlay :show="loading">
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                <b-row>
                                     <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Farmer" vid="mac_farmer_id" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="nid"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('common_config.farmer_nid_no') }} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="nid"
                                                v-model="formData.nid_no"
                                                :placeholder="$t('dae_grant_allocation_distribution.type_nid')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :disabled="farmerExists"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                     <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="mobile_no" vid="mobile_no">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="mobile_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.mobile_no')}}
                                            </template>
                                                <b-form-input
                                                id="mobile_no"
                                                v-model="formData.mobile_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :disabled="farmerExists"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                                        <b-button v-if="onlyCreate" type="button" variant="primary"  @click="searchFarmer">{{ $t('globalTrans.search') }}</b-button>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="2" lg="2" xl="2" class="text-right">
                                        <b-button v-if="onlyCreate" type="button" variant="warning"  @click="clearForm">{{ $t('globalTrans.reset') }}</b-button>
                                    </b-col>
                                </b-row>
                                <template>
                                    <div class="col-sm-12 col-md-12" style="font-size:12px; background-color: #337982; padding:6px">
                                        <h5 class="text-white text-center">
                                            {{ $t('admission_form.gen_inf') }}
                                        </h5>
                                    </div>
                                </template>
                            <!-- =======================Demand Info Start======================= -->
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="fiscal_year_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.fiscal_year')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.fiscal_year_id"
                                                :options="fiscalYearList"
                                                id="fiscal_year_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="season_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.season')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.season_id"
                                                :options="seasonList"
                                                id="season_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="circular_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.circular')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.circular_id"
                                                :options="circularList"
                                                id="circular_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="Subsidy" vid="subsidy_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="subsidy_type_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.subsidy_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.subsidy_type_id"
                                                :options="subsidyTypeList"
                                                id="subsidy_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                    <!-- =======================Demand Info End========================= -->
                    <!-- =======================Farmer General Info Start======================= -->
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Name (En)" vid="name">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_name')}}
                                            </template>
                                                <b-form-input
                                                id="name"
                                                v-model="formData.name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Farmer Father Name" vid="father_name">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="father_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_father_name_en')}}
                                            </template>
                                                <b-form-input
                                                id="father_name"
                                                v-model="formData.father_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Mother Name" vid="mother_name">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="mother_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_mother_name_en')}}
                                            </template>
                                                <b-form-input
                                                id="season_name"
                                                v-model="formData.mother_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="date_of_birth" vid="date_of_birth">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="date_of_birth"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_date_of_birth')}}
                                            </template>
                                                <b-form-input
                                                id="date_of_birth"
                                                v-model="formData.date_of_birth"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="gender" vid="gender">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="gender"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_gender')}}
                                            </template>
                                                <b-form-input
                                                id="gender"
                                                v-model="formData.gender"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="mobile_no" vid="mobile_no">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="mobile_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.mobile_no')}}
                                            </template>
                                                <b-form-input
                                                id="mobile_no"
                                                v-model="formData.mobile_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- =======================Farmer General Info End========================= -->
                                    <template>
                                         <div style="font-size:12px; background-color: #337982; padding:6px">
                                            <h5 class="text-white text-center">
                                                {{ $t('dae_grant_allocation_distribution.frmr_pre') }}
                                            </h5>
                                        </div>
                                    </template>
                                <!-- =======================Farmer Permanent Address Start========================= -->
                                 <div class="row">
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="division" vid="division">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="division"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.division')}}
                                            </template>
                                                <b-form-input
                                                id="division_name"
                                                v-model="formData.division_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="District" vid="district">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.district')}}
                                            </template>
                                                <b-form-input
                                                id="season_name"
                                                v-model="formData.district_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="upazilla" vid="upazila">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="upazila"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.upazila')}}
                                            </template>
                                                <b-form-input
                                                id="season_name"
                                                v-model="formData.upazilla_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="union" vid="union">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="union"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.union')}}
                                            </template>
                                                <b-form-input
                                                id="union"
                                                v-model="formData.union_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            <!-- =======================Farmer Present Address End=========================== -->
                                <template>
                                        <div style="font-size:12px; background-color: #337982; padding:6px">
                                            <h5 class="text-white text-center">
                                            {{ $t('dae_grant_allocation_distribution.frmr_per') }}
                                        </h5>
                                    </div>
                                </template>
                            <!-- =======================Farmer Permanent Address Start========================= -->
                            <div class="row">
                                <div class="col-sm-6 col-md-6 mt-2">
                                    <ValidationProvider name="division" vid="division">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="division"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.division')}}
                                        </template>
                                            <b-form-input
                                            id="division_name"
                                            v-model="formData.per_division_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6 mt-2">
                                    <ValidationProvider name="district" vid="district">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="district"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.district')}}
                                        </template>
                                            <b-form-input
                                            id="district"
                                            v-model="formData.per_district_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <ValidationProvider name="upazila" vid="upazila">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="upazila"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.upazila')}}
                                        </template>
                                            <b-form-input
                                            id="upazila"
                                            v-model="formData.per_upazilla_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <ValidationProvider name="union" vid="union">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="union"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.union')}}
                                        </template>
                                            <b-form-input
                                            id="union"
                                            v-model="formData.per_union_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <template>
                                <div style="font-size:12px; background-color: #337982; padding:6px">
                                    <h5 class="text-white text-center">
                                    {{ $t('externalIncentive.machineries_info') }}
                                </h5>
                                </div>
                            </template>
                            <b-row class="mb-2">
                                <b-col sm="3">
                                    <ValidationProvider name="Machine Type" vid="machine_type_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="machine_type_id"
                                            slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                             {{ $t('farm_config.machine_type') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="formData.machine_type_id"
                                            :options="machineTypeList"
                                            id="machine_type_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Instrument" vid="instrument_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="instrument_id"
                                            slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{ $t('dae_subsidy.instrument_name') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="formData.instrument_id"
                                            :options="instrumentList"
                                            id="instrument_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Brand" vid="brand_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="brand_id"
                                            slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{ $t('farm_config.brand') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="formData.brand_id"
                                            :options="brandList"
                                            id="brand_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="Model" vid='model' rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="model"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{ $t('dae_subsidy.model')}}<span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="model"
                                            v-model="formData.model"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider name="application Type" vid="application_type" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="application_type"
                                            slot-scope="{ valid, errors }">
                                            <template v-slot:label>
                                            {{ $t('dae_subsidy.applicationType') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="formData.application_type"
                                            :options="applicationTypeList"
                                            id="application_type"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <div v-if="formData.application_type === 2" class="mb-3">
                                <template>
                                    <b-row>
                                        <b-col>
                                            <h5 class="ex_form_title">{{ $t('externalIncentive.group_indi_info') }}</h5>
                                        </b-col>
                                    </b-row>
                                </template>
                                <template>
                                    <b-row v-for="(detail,index) in formData.details" :key="index">
                                        <b-col sm="3">
                                            <ValidationProvider name="Farmer Name (En)" :vid="`farmer_name${index}`" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    :label-for="`farmer_name${index}`"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('dae_subsidy.farmer_name_en')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="farmer_name"
                                                    v-model="detail.farmer_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Farmer Name (Bn)" :vid="`farmer_name_bn${index}`" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    :label-for="`farmer_name_bn${index}`"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('dae_subsidy.farmer_name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="farmer_name_bn"
                                                v-model="detail.farmer_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Father Name (En)" :vid="`father_name${index}`" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    :label-for="`father_name${index}`"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{$t('dae_subsidy.father_name_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="father_name"
                                                    v-model="detail.father_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Father Name (Bn)" :vid="`father_name_bn${index}`" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    :label-for="`father_name_bn${index}`"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{$t('dae_subsidy.father_name_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="father_name_bn"
                                                    v-model="detail.father_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Mobile Number" :vid="`mobile_no${index}`" rules="digits:11|required">
                                                <b-form-group
                                                class="row"
                                                type="number"
                                                label-cols-sm="12"
                                                :label-for="`mobile_no${index}`"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('dae_subsidy.mobile_no')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                type="number"
                                                id="mobile_no"
                                                v-model="detail.mobile_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="NID" :vid="`nid${index}`" rules="required|max:17">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                :label-for="`nid${index}`"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('dae_subsidy.nid')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                type="number"
                                                id="nid"
                                                v-model="detail.nid"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <div class="text-left" style="margin-top: 38px;">
                                                <b-button variant="success" size="sm" class="mr-1" @click="add()"><i class="ri-add-line m-0">{{ $t('globalTrans.add_more') }}</i></b-button>
                                                <b-button v-show="index || ( !index && formData.details.length > 1)" variant="danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </template>
                            </div>
                            <div class="row">
                                <div class="col-sm-9"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1">
                                        <router-link :to="{ path: '/incentive-grant-service/dae/subsidy/application-management'}" size="sm" style="color:#fff !important">
                                           {{ $t('globalTrans.cancel') }}
                                        </router-link>
                                    </b-button>
                                </div>
                            </div>
                            </b-form>
                        </ValidationObserver>
                    </template>
                </iq-card>
            </b-col>
        </b-overlay>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicationStore, subsidyDemandCollectionUpdate, machineryFarmerAllInfo, circularDataApi, circInstrList } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            onlyCreate: true,
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                nid_no: '',
                mobile_no: '',
                fiscal_year_id: 0,
                circular_id: 0,
                season_id: 0,
                subsidy_type_id: 1,
                project_id: 0,
                mac_farmer_id: 0,
                name: '',
                mother_name: '',
                father_name: '',
                date_of_birth: '',
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                union_id: 0,
                subsidy_type_name: '',
                division_name: '',
                district_name: '',
                upazilla_name: '',
                union_name: '',
                per_division_id: 0,
                per_district_id: 0,
                per_upazila_id: 0,
                per_union_id: 0,
                per_division_name: '',
                per_district_name: '',
                per_upazilla_name: '',
                per_union_name: '',
                machine_type_id: 0,
                instrument_id: 0,
                brand_id: 0,
                application_type: '',
                details: [
                    {
                        farmer_name: '',
                        farmer_name_bn: '',
                        father_name: '',
                        father_name_bn: '',
                        mobile_no: '',
                        nid: ''
                    }
                ]
            },
            subsidyNameList: [],
            instrumentList: [],
            instrumentListData: [],
            brandList: [],
            circularList: [],
            machineTypeList: [],
            farmerExists: false
        }
    },
    created () {
        if (this.id) {
            this.onlyCreate = false
            const tmp = this.getformDataData()
            this.formData.circular_id = tmp.circular_id
            this.formData.nid_no = tmp.nid_no
            this.formData.land_quantity = tmp.land_quantity
            this.getCircularDetails(tmp.circular_id)
            this.searchFarmer()
            this.formData.crop_id = tmp.demand_collection_details.map(item => {
                return item.crop_id
            })
        }
        this.getCurrentFiscalYear()
    },
    computed: {
        currentLocale: function () {
            return this.$i18n.locale
        },
        fiscalYearList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        seasonList: function () {
            return [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
            ]
        },
        // circularList: function () {
        //     const today = new Date().toISOString().slice(0, 10)
        //     const circulars = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === 1 && item.application_deadline >= today)
        //     return circulars.map((obj, key) => {
        //         if (this.$i18n.locale === 'bn') {
        //             return { value: obj.value, text: obj.text_bn }
        //         } else {
        //             return { value: obj.value, text: obj.text_en }
        //         }
        //     })
        // },
        // machineTypeList: function () {
        //     return this.$store.state.incentiveGrant.commonObj.macMachineTypeList.filter(item => item.status === 1)
        // },
        applicationTypeList: function () {
            const dataList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Individual' : 'ব্যক্তিগত' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Group' : 'গ্রুপ' }
            ]
            return dataList
        },
        subsidyTypeList: function () {
            const dataList = [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'ভর্তুকি' : 'Subsidy' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'প্রণোদনা' : 'Incentive' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'পুনর্বাসন' : 'Rehabilitation' }
            ]
            return dataList
        }
    },
    mounted () {
        core.index()
    },
    watch: {
        currentLocale: function () {
            this.circularList = this.getCircularList(this.formData.fiscal_year_id, parseInt(this.formData.season_id))
        },
        'formData.machine_type_id': function (newVal, oldVal) {
            if (newVal !== 0) {
                this.instrumentList = this.instrumentListData.filter(item => item.machine_type_id === newVal)
            }
        },
        'formData.instrument_id': function (newVal, oldVal) {
            if (newVal !== 0) {
                this.brandList = this.$store.state.incentiveGrant.commonObj.macBrandList.filter(item => item.instrument_name_id === newVal)
            }
        },
        'formData.circular_id': function (newVal, oldVal) {
          if (newVal !== oldVal && newVal > 0) {
            this.getInstrumentList(newVal)
          }
        },
        'formData.fiscal_year_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            if (parseInt(this.formData.season_id) > 0) {
                this.circularList = this.getCircularList(newVal, parseInt(this.formData.season_id))
            }
          }
        },
        'formData.season_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            if (parseInt(this.formData.fiscal_year_id) > 0) {
                this.circularList = this.getCircularList(parseInt(this.formData.fiscal_year_id), newVal)
            }
          }
        }
    },
    methods: {
        getCircularList (fiscalYearId, seasonId) {
            const today = new Date().toISOString().slice(0, 10)
            return this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.fiscal_year_id === fiscalYearId && item.type === 1 && item.application_deadline >= today && item.season_id === seasonId)
        },
        getCurrentFiscalYear () {
            const fiscalYearList = this.$store.state.commonObj.fiscalYearList
            const currentDate = new Date()
            const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
            const yearPosition = month < 7 ? 5 : 0
            const yearStr = `${year}`
            fiscalYearList.forEach(element => {
                if (element.text_en.indexOf(yearStr) === yearPosition) {
                    this.formData.fiscal_year_id = element.value
                }
            })
        },
        async getCircularDetails (circularId) {
            this.loading = true
            const params = Object.assign({}, { circular_id: circularId })
            await RestApi.getData(incentiveGrantServiceBaseUrl, circularDataApi, params).then(response => {
                if (response.success) {
                    this.formData.fiscal_year_id = response.data.fiscal_year_id
                    this.formData.season_id = response.data.season_id
                    this.formData.project_id = response.data.project_id
                    this.formData.subsidy_type = response.data.circular_type
                    if (response.data.circular_type === 1) {
                        this.formData.subsidy_type_name = this.$i18n.locale === 'bn' ? 'ভর্তুকি' : 'Subsidy'
                    } else if (response.data.circular_type === 2) {
                        this.formData.subsidy_type_name = this.$i18n.locale === 'bn' ? 'প্রণোদনা' : 'Incentive'
                    } else if (response.data.circular_type === 3) {
                        this.formData.subsidy_type_name = this.$i18n.locale === 'bn' ? 'পুনর্বাসন' : 'Rehabilitation'
                    }
                }
                this.loading = false
            })
        },
        async searchFarmer () {
            this.farmerExists = true
            this.loading = true
            const params = { nid_no: this.formData.nid_no, mobile_no: this.formData.mobile_no, upazilla_id: this.formData.upazilla_id }
            await RestApi.getData(incentiveGrantServiceBaseUrl, machineryFarmerAllInfo, params).then(response => {
                if (response.success) {
                    this.formData.mac_farmer_id = response.data.id
                    this.formData.date_of_birth = response.data.date_of_birth
                    // this.formData.blood_group_id = response.data.blood_group_id
                    this.formData.father_name = this.$i18n.locale === 'bn' ? response.data.father_name_bn : response.data.father_name
                    this.formData.name = this.$i18n.locale === 'bn' ? response.data.name_bn : response.data.name
                    this.formData.mother_name = this.$i18n.locale === 'bn' ? response.data.mother_name_bn : response.data.mother_name
                    if (response.data.gender_id === 1) {
                        this.formData.gender = this.$i18n.locale === 'bn' ? 'পুরুষ' : 'Male'
                    }
                    if (response.data.gender_id === 2) {
                        this.formData.gender = this.$i18n.locale === 'bn' ? 'মহিলা' : 'Female'
                    }
                    if (response.data.gender_id === 3) {
                        this.formData.gender = 'Others'
                    }
                    this.formData.nid_no = response.data.nid_no
                    this.formData.mobile_no = response.data.mobile_no
                    // --------Present Address Obj Start-------
                    const divisionObject = this.$store.state.commonObj.divisionList.find(division => division.value === response.data.division_id)
                    this.formData.division_id = response.data.division_id
                    this.formData.division_name = divisionObject !== undefined ? this.$i18n.locale === 'bn' ? divisionObject.text_bn : divisionObject.text_en : ''
                    const districtObject = this.$store.state.commonObj.districtList.find(district => district.value === response.data.district_id)
                    this.formData.district_id = response.data.district_id
                    this.formData.district_name = districtObject !== undefined ? this.$i18n.locale === 'bn' ? districtObject.text_bn : districtObject.text_en : ''
                    const upazillaObject = this.$store.state.commonObj.upazilaList.find(upazillaItem => upazillaItem.value === response.data.upazilla_id)
                    this.formData.upazilla_id = response.data.upazilla_id
                    this.formData.upazilla_name = upazillaObject !== undefined ? this.$i18n.locale === 'bn' ? upazillaObject.text_bn : upazillaObject.text_en : ''
                    const unionObject = this.$store.state.commonObj.unionList.find(unionItem => unionItem.value === response.data.union_id)
                    this.formData.union_id = response.data.union_id
                    this.formData.union_name = unionObject !== undefined ? this.$i18n.locale === 'bn' ? unionObject.text_bn : unionObject.text_en : ''
                    // const wardObject = this.$store.state.commonObj.wardList.find(wardItem => wardItem.value === response.data.ward_id)
                    // this.formData.ward_no = wardObject !== undefined ? this.$i18n.locale === 'bn' ? wardObject.text_bn : wardObject.text_en : ''
                    // this.formData.village = this.$i18n.locale === 'bn' ? response.data.village_bn : response.data.village_en
                    // this.formData.block = this.$i18n.locale === 'bn' ? response.data.block_bn : response.data.block_en
                    // --------Present Address Obj End-------
                    // ----------PermanenetAddressObjStart------
                    const perDivisionObject = this.$store.state.commonObj.divisionList.find(preDivision => preDivision.value === response.data.per_division_id)
                    this.formData.per_division_id = response.data.per_division_id
                    this.formData.per_division_name = perDivisionObject !== undefined ? this.$i18n.locale === 'bn' ? perDivisionObject.text_bn : divisionObject.text_en : ''
                    const perDistrictObject = this.$store.state.commonObj.districtList.find(perDistrict => perDistrict.value === response.data.per_district_id)
                    this.formData.per_district_id = response.data.per_district_id
                    this.formData.per_district_name = perDistrictObject !== undefined ? this.$i18n.locale === 'bn' ? perDistrictObject.text_bn : perDistrictObject.text_en : ''
                    const perUpazillaObject = this.$store.state.commonObj.upazilaList.find(perUpazillaItem => perUpazillaItem.value === response.data.per_upazilla_id)
                    this.formData.per_upazila_id = response.data.per_upazilla_id
                    this.formData.per_upazilla_name = perUpazillaObject !== undefined ? this.$i18n.locale === 'bn' ? perUpazillaObject.text_bn : perUpazillaObject.text_en : ''
                    const perUnionObject = this.$store.state.commonObj.unionList.find(perUnionItem => perUnionItem.value === response.data.per_union_id)
                    this.formData.per_union_id = response.data.per_union_id
                    this.formData.per_union_name = perUnionObject !== undefined ? this.$i18n.locale === 'bn' ? perUnionObject.text_bn : perUnionObject.text_en : ''
                    // const perWardObject = this.$store.state.commonObj.wardList.find(perWardItem => perWardItem.value === response.data.per_ward_id)
                    // this.formData.per_ward_no = perWardObject !== undefined ? this.$i18n.locale === 'bn' ? perWardObject.text_bn : perWardObject.text_en : ''

                    // this.formData.per_village = this.$i18n.locale === 'bn' ? response.data.per_village_bn : response.data.per_village_en
                    // this.formData.per_block = this.$i18n.locale === 'bn' ? response.data.per_block_bn : response.data.per_block_en
                // ----------PermanenetAddressObjEnd------
                } else {
                     window.vm.$toast.error({
                        title: 'Error',
                        message: response.message
                    })
                }
                this.loading = false
            })
        },
        getformDataData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${subsidyDemandCollectionUpdate}/${this.id}`, this.formData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, applicationStore, this.formData)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$router.push({ path: '/incentive-grant-service/dae/subsidy/application-management' })
            } else {
                if (result.errors && result.errors.mac_farmer_id) {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('externalIncentive.alreadyApplied'),
                        color: '#D6E09B'
                    })
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#D6E09B'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
                this.$refs.form.setErrors(result.errors)
            }
        },
        async getInstrumentList (circularId) {
            this.loading = true
          await RestApi.getData(incentiveGrantServiceBaseUrl, `${circInstrList}/${circularId}`)
          .then(response => {
            if (response.success) {
              const datas = response.data.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn, machine_type_id: obj.machine_type_id }
                } else {
                  return { value: obj.value, text: obj.text_en, machine_type_id: obj.machine_type_id }
                }
              })
              this.instrumentListData = datas
               this.instrumentListData.map(item => {
                   const matTypeObj = this.$store.state.incentiveGrant.commonObj.macMachineTypeList.find(obj => obj.value === item.machine_type_id)
                    if (this.machineTypeList.length > 0) {
                        if (!this.machineTypeList.find(obj => obj.value === item.machine_type_id)) { // check if already exist in machineTypeList
                            if (this.$i18n.locale === 'bn') {
                                this.machineTypeList.push({ value: matTypeObj.value, text: matTypeObj.text_bn, text_en: matTypeObj.text_en, text_bn: matTypeObj.text_bn })
                            } else {
                                this.machineTypeList.push({ value: matTypeObj.value, text: matTypeObj.text_en, text_en: matTypeObj.text_en, text_bn: matTypeObj.text_bn })
                            }
                        }
                    } else {
                        if (this.$i18n.locale === 'bn') {
                            this.machineTypeList.push({ value: matTypeObj.value, text: matTypeObj.text_bn, text_en: matTypeObj.text_en, text_bn: matTypeObj.text_bn })
                        } else {
                            this.machineTypeList.push({ value: matTypeObj.value, text: matTypeObj.text_en, text_en: matTypeObj.text_en, text_bn: matTypeObj.text_bn })
                        }
                    }
                })
            }
            this.loading = false
          })
        },
        add () {
          const tampbpSetup = {
            farmer_name: '',
            farmer_name_bn: '',
            father_name: '',
            father_name_bn: '',
            mobile_no: '',
            nid: ''
          }
          const key1 = parseInt(this.formData.details.length - 1)
          const item = this.formData.details[key1]
          let isEmpty = true
          Object.keys(item).map(key => {
              if (item[key] === '' || item[key] === 0) {
                 isEmpty = false
              }
          })
          if (isEmpty === true) {
            this.formData.details.push(tampbpSetup)
          }
        },
        remove (key) {
          this.formData.details.splice(key, 1)
        },
        clearForm () {
            this.farmerExists = false
            this.formData.nid_no = ''
            this.formData.mobile_no = ''
        }
    }
}
</script>
